import React, { Component } from 'react'

import creat_resume_img1 from './main_page/img/creat-resume-img1.png'
import creat_resume_img2 from './main_page/img/creat-resume-img2.png'
import creat_resume_img3 from './main_page/img/creat-resume-img3.png'
import creat_resume_img4 from './main_page/img/creat-resume-img4.png'
import creat_resume_img5 from './main_page/img/creat-resume-img5.png'
import creat_resume_img6 from './main_page/img/creat-resume-img6.png'

class MainContent extends Component {

    // appendScript = (scriptToAppend) => {
    //     const script = document.createElement("script");
    //     script.src = scriptToAppend;
    //     script.async = true;
    //     document.body.appendChild(script);
    // }    

    // componentDidMount(){
    //     this.appendScript("./main_page/js/scrollIt.min.js");
    //     this.appendScript("./main_page/js/bootstrap.min.js");
    //     this.appendScript("./main_page/js/animated.headline.js");
    //     this.appendScript("./main_page/js/jquery.waypoints.min.js");
    //     this.appendScript("./main_page/js/slider-js.js");
    //     this.appendScript("./main_page/js/owl.carousel.min.js");
    //     this.appendScript("./main_page/js/scripts.js");
    // }

    render(){
        return(
            <div>
                <header className="header slider" style={{background: "#eaeaea"}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="owl-carousel owl-theme fxSoftScale full-width">
                                <div className="text-center item">
                                    {/* <!-- <img src="img/slider-1.jpg" alt="" /> --> */}
                                    <section className="online-resume-div">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-xl-6 col-md-12">
                                                    <div className="online-resume-content">
                                                        <h3>
                                                            The Online Resume Builder <br /> that you deserve!
                                                        </h3>
                                                        <h5>
                                                            Creating a professional resume <br /> has never been so easy
                                                        </h5>
                                                        <a href="#loginarea" data-scroll-nav="0" className="butn">
                                                            <span>BUILD MY RESUME NOW!</span>
                                                        </a>

                                                        
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-12">
                                                    <div className="online-resume-img-div animated fadeInUp">
                                                        <img src="img/slider-1.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <div className="text-center item">
                                    {/* <!-- <img src="img/slider-2.jpg" alt="" /> --> */}
                                    <section className="online-resume-div">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="online-resume-content">
                                                        <h3>
                                                        Creating a resume shouldn't be a nightmare! 
                                                        </h3>
                                                        <h5>
                                                        Simply enter your information and<br /> get a   beautifully formatted resume in minutes
                                                        </h5>
                                                        <a href="#loginarea" data-scroll-nav="0" className="butn">
                                                            <span>BUILD MY RESUME NOW!</span>
                                                        </a>

                                                        
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-12">
                                                    <div className="online-resume-img-div animated fadeInUp">
                                                        <img src="img/slider-2.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <div className="text-center item">
                                    {/* <!-- <img src="img/slider-3.jpg" alt="" /> --> */}
                                    <section className="online-resume-div">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="online-resume-content">
                                                        <h3>
                                                            Create once & share everywhere!
                                                        </h3>
                                                        <h5>
                                                            Create your resume in few steps and share  <br /> it as a PDF or online link
                                                        </h5>
                                                        <a href="#loginarea" data-scroll-nav="0" className="butn">
                                                            <span>BUILD MY RESUME NOW!</span>
                                                        </a>

                                                        
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-12">
                                                    <div className="online-resume-img-div animated fadeInUp">
                                                        <img src="img/slider-3.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                            
                            </div>
                        </div>
                    </div>

                    <div className="arrow">
                        <a href="#" data-scroll-nav="1">
                            <i className="fas fa-chevron-down"></i>
                        </a>
                    </div>
                </header>

                <section className="creat-resume-main bg-gray section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-sm-12">
                                <div className="section-head text-center">
                                    <h3>
                                        Create a resume that gets results!
                                    </h3>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <div className="creat-resume-content">
                                    <img className="section1-img" src={creat_resume_img1} alt="" />
                                    <h5>
                                        Online Resume Builder
                                    </h5>
                                    <p>
                                    Step-by-step guided process to make it easy with pre-written text examples.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-12">
                                <div className="creat-resume-content">
                                    <img className="section1-img" src={creat_resume_img2} alt="" />
                                    <h5>
                                        Out of Box Templates
                                    </h5>
                                    <p>
                                        Employer tested templates to make your resume shine.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-12">
                                <div className="creat-resume-content">
                                    <img className="section1-img" src={creat_resume_img3} alt="" />
                                    <h5>
                                        Unique Resume Link
                                    </h5>
                                    <p>
                                        Create online version of your resume and share the link to recruiters & employers.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-12">
                                <div className="creat-resume-content">
                                    <img className="section1-img" src={creat_resume_img4} alt="" />
                                    <h5>
                                    Create PDF Files
                                    </h5>
                                    <p>
                                    Instantly download your completed resumes in PDF format.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-12">
                                <div className="creat-resume-content">
                                    <img className="section1-img" src={creat_resume_img5} alt="" />
                                    <h5>
                                        Fast Easy Formatting
                                    </h5>
                                    <p>
                                        Customize your resume for each section and we’ll make it fit perfectly.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-12">
                                <div className="creat-resume-content">
                                    <img className="section1-img" src={creat_resume_img6} alt="" />
                                    <h5>
                                    Visually Stunning
                                    </h5>
                                    <p>
                                    Fast, easy to use and you can create your resume in a clean and visually appealing way.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section id="section2" className="resume-builder section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 col-sm-12">
                                <div className="section-head text-center">
                                    <h3 className="mb-10">
                                        How Resume Builder Works
                                    </h3>
                                    <p style={{width: "80%", margin: "0 auto"}}>
                                        Our Online Resume Builder lets you build a powerful resume from scratch and handles everything else in just four simple steps.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-8 offset-lg-2 col-sm-12">
                            {/* <!--  https://www.tutorialrepublic.com/codelab.php?topic=bootstrap&file=accordion-with-plus-minus-icon --> */}

                                <div className="accordion" id="accordionExample">
                                    <div className="card">
                                        <button type="button" className="btn btn-link" data-toggle="collapse" data-target="#collapseOne">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <h5>
                                                        Step 1
                                                    </h5>
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="card-header" id="headingOne">
                                                        <h2 className="mb-0">
                                                            <i className="fa fa-plus"></i> 
                                                            One click login via Facebook & Google                              
                                                        </h2>
                                                    </div>
                                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                        <div className="card-body">
                                                            <p>
                                                                You can login using your Facebook or Google account in one click- you're in safe hands and we have no access to your social accounts.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>  
                                        
                                    </div>

                                    <div className="card">
                                        <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <h5 className="color-2">
                                                        Step 2
                                                    </h5>
                                                </div>

                                                <div className="col-md-10">
                                                    <div className="card-header" id="headingTwo">
                                                        <h2 className="mb-0">
                                                            <i className="fa fa-plus"></i> 
                                                            Fill out the out of box template
                                                        </h2>
                                                    </div>
                                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                                        <div className="card-body">
                                                            <p>
                                                                Resume Builder walks you through the step by step process to fill out the template within a few minutes.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                        
                                    </div>
                                    
                                    <div className="card">
                                        <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <h5 className="color-3">
                                                        Step 3
                                                    </h5>
                                                </div>

                                                <div className="col-md-10">
                                                    <div className="card-header" id="headingThree">
                                                        <h2 className="mb-0">
                                                            <i className="fa fa-plus"></i> 
                                                            Customize your resume                 
                                                        </h2>
                                                    </div>
                                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                                        <div className="card-body">
                                                            <p>
                                                                You can pick your favourite color & font to make your resume more attractive to the recruiters & employers.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </button> 
                                    </div>


                                    <div className="card">
                                        <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <h5 className="color-4">
                                                        Step 4
                                                    </h5>
                                                </div>

                                                <div className="col-md-10">
                                                    <div className="card-header" id="headingFour">
                                                        <h2 className="mb-0">
                                                            <i className="fa fa-plus"></i> 
                                                            Save, Download & Share                  
                                                        </h2>
                                                    </div>
                                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                                        <div className="card-body">
                                                            <p>
                                                                You can save your resume and retrieve later; download a PDF version or share your resume online.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>  
                                    </div>


                                </div>
                                <div className="resume-builder-content">
                                    {/* <!-- <ul>
                                        <li>
                                            <span>
                                                Step 1 &nbsp; +  &nbsp;
                                            </span> 
                                            Pick from professionally designed resume templates
                                        </li>

                                        <li>
                                            <span>
                                                Step 2 &nbsp; +  &nbsp;
                                            </span> 
                                            Answer a few questions about your work history
                                        </li>

                                        <li>
                                            <span>
                                                Step 3 &nbsp; +  &nbsp;
                                            </span> 
                                            Use our expert advice to personalize your resume
                                        </li>

                                        <li>
                                            <span>
                                                Step 4 &nbsp; +  &nbsp;
                                            </span> 
                                            Review your new resume
                                        </li>
                                    </ul> --> */}

                                    <center>
                                        <a href="#loginarea" data-scroll-nav="0" className="butn">
                                            <span>BUILD MY RESUME NOW! <i className="fa fa-angle-double-right" aria-hidden="true"></i></span>
                                        </a>
                                    </center>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default MainContent