import React from 'react'
import { Router, Route, browserHistory } from 'react-router'
import App from './components/App/App';
import login from './components/AuthComponent/login';
import home from './components/AuthComponent/home';
import admin from './components/AuthComponent/admin';

import { Redirect } from "react-router-dom"
import requireAuth from './components/AuthComponent/authcomponent'
import OpenResume from './components/App/openresume';

function PrivateRoute({ component: Component, authed }) {
    return (
        <Route
            render={(props) => authed === true
                ? ''
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
        />
    )
}



// eslint-disable-next-line react/prefer-stateless-function
class MyRouter extends React.Component {
    // eslint-disable-next-line react/state-in-constructor

    CheckUserAuth = () => {
        if (localStorage.getItem("UserId")) {
            return true;
        } else {
            return false;
        }
    }

    render() {

        return (
            <Router history={browserHistory}>
                <PrivateRoute authed={this.CheckUserAuth} path="/" component={requireAuth(App)} />
                <PrivateRoute authed={this.CheckUserAuth} path="/resume" component={requireAuth(home)} />
                <Route path="/OpenResume" component={OpenResume} />
                <Route path="/login" component={login} />
                <Route path="/login" component={login} />
                <PrivateRoute authed={this.CheckUserAuth} path="/myadmin" component={requireAuth(admin)} />
            </Router>
        )
    }
}
export default MyRouter