import React from 'react'
import NavbarHome from './NavbarHome'
import DataTable, { createTheme } from 'react-data-table-component';
import axios from "axios"
import { Url } from "./../services/commonservice"
// import {
//     Button
// } from "reactstrap"
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

createTheme('solarized', {
    text: {
        primary: '#268bd2',
        secondary: '#2aa198',
    },
    background: {
        default: '#002b36',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#073642',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
});

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
const columns = [
    {
        name: 'User Id',
        selector: 'userid',
        sortable: true,
    },
    {
        name: 'Login With',
        selector: 'loginwith',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Email',
        selector: 'email',
        sortable: true,
    },
    {
        name: 'Registration Date',
        selector: 'regisrtaiondate',
        sortable: true,
    },
    {
        name: 'Total Resume',
        selector: 'totalresume',
        sortable: true,
    },
    {
        name: 'Last Login',
        selector: 'lastlogin',
        sortable: true,
    },
];






// eslint-disable-next-line react/prefer-stateless-function
class admin extends React.Component {



    // eslint-disable-next-line react/state-in-constructor
    state = {
        // eslint-disable-next-line react/no-unused-state
        data: [],
        status: false,
        email: '',
        password: ''
    }

    // eslint-disable-next-line react/state-in-constructor
    componentDidMount() {
        this.GetUserInfo();
    }

    GetUserInfo = () => {
        axios.get(Url + "GetUserList").then((res: any) => {
            res = res.data;
            this.setState({ data: res.Data });
            // console.log(res)
        });
    }


    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    convertArrayOfObjectsToCSV = (array) => {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(this.state.data[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    downloadCSV = () => {
        const link = document.createElement('a');
        let csv = this.convertArrayOfObjectsToCSV(this.state.data);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    validateForm = () => {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleSubmit = (event) => {

        event.preventDefault();
        if (this.state.email == 'info@wesupportrajini.com' && this.state.password === 'wesupport@123') {
            this.setState({ status: true });
            toast.success("Admin Login Successfully", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.error("Please Check Username or Password", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    render() {
        return (
            <div id="myhome">
                <NavbarHome />
                {
                    this.state.status ? (
                        <div id="adminportal">
                            <Button id="exportcsv" variant="outline-primary" onClick={() => { this.downloadCSV() }}><FontAwesomeIcon icon={faDownload} /> Export</Button>{' '}
                            <DataTable
                                title="Register Users"
                                columns={columns}
                                data={this.state.data}
                                pagination={1}
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100, 200]}
                                paginationPerPage={10}
                            />
                        </div>
                    ) : (
                            <div id="adminlogin">
                                <div >
                                    <form onSubmit={this.handleSubmit}>
                                        <FormGroup controlId="email" bsSize="large">
                                            <FormLabel>Email</FormLabel>
                                            <FormControl
                                                autoFocus
                                                type="email"
                                                // eslint-disable-next-line react/destructuring-assignment
                                                value={this.state.email}
                                                onChange={e => { this.setState({ email: e.target.value }); }}
                                            />
                                        </FormGroup>
                                        <FormGroup controlId="password" bsSize="large">
                                            <FormLabel>Password</FormLabel>
                                            <FormControl
                                                // eslint-disable-next-line react/destructuring-assignment
                                                value={this.state.password}
                                                onChange={e => { this.setState({ password: e.target.value }); }}
                                                type="password"
                                            />
                                        </FormGroup>
                                        <Button block bsSize="large" disabled={!this.validateForm()} type="submit">
                                            Login
                                        </Button>
                                    </form>
                                </div>
                            </div>
                        )
                }
            </div>)

    }
}
export default admin