import React, { useContext } from 'react';
import { Url } from "../components/services/commonservice"
import PageContext from '../context/PageContext';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const setDownloadDialogOpen = () => {
  let id = getParams(window.location.href)['id'];
  console.log(id);
  axios.post(Url + "SaveUserResume", { id: id || 0, 'userid': localStorage.getItem("UserId"), 'resume_info': localStorage.getItem('state') }).then((res) => {
    res = res.data;
    if (res.Status === 1) {
      toast.success(res.Message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setTimeout(() => {
        window.location.href = '/resume';
      }, 1000);
    }
  });
}

const ClearInfo = () => {
  let test = { "data": { "profile": { "heading": "Profile", "photo": "", "firstName": "", "lastName": "", "subtitle": "", "address": { "line1": "", "line2": "", "line3": "" }, "phone": "", "website": "", "email": "" }, "objective": { "enable": true, "heading": "Objective", "body": "" }, "work": { "enable": true, "heading": "Work Experience", "items": [] }, "education": { "enable": true, "heading": "Education", "items": [] }, "awards": { "enable": true, "heading": "Honors & Awards", "items": [] }, "certifications": { "enable": true, "heading": "Certifications", "items": [] }, "skills": { "enable": true, "heading": "Skills", "items": [] }, "hobbies": { "enable": true, "heading": "Hobbies", "items": [] }, "languages": { "enable": true, "heading": "Languages", "items": [] }, "references": { "enable": true, "heading": "References", "items": [] }, "extras": { "enable": true, "heading": "Personal Information", "items": [] } }, "theme": { "layout": "Onyx", "font": { "family": "" }, "colors": { "background": "#ffffff", "primary": "#212121", "accent": "#f44336" } }, "settings": { "language": "en" } };
  localStorage.setItem("state", JSON.stringify(test));
  window.location.reload();
}

const getParams = (url) => {
  var params = {};
  var parser = document.createElement('a');
  parser.href = url;
  var query = parser.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
}


const PageController = () => {
  const pageContext = useContext(PageContext);
  const { panZoomRef, setPrintDialogOpen } = pageContext;

  const zoomIn = () => panZoomRef.current.zoomIn(2);
  const zoomOut = () => panZoomRef.current.zoomOut(2);
  const centerReset = () => {
    panZoomRef.current.autoCenter(1);
    panZoomRef.current.reset(1);
  };



  return (
    <div
      id="pageController"
      className="absolute z-20 opacity-75 hover:opacity-100 transition-all duration-150"
    >
      <div className="text-2xl px-8 border border-gray-200 rounded-full bg-white flex justify-center items-center leading-none select-none">
        <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={zoomIn}>
          <i className="material-icons">zoom_in</i>
        </div>

        <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={zoomOut}>
          <i className="material-icons">zoom_out</i>
        </div>

        <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={centerReset}>
          <i className="material-icons">center_focus_strong</i>
        </div>

        <div className="text-gray-400 p-3">|</div>

        <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={() => window.print()}>
          <i className="material-icons">print</i>
        </div>

        <div
          className="p-3 hover:bg-gray-200 cursor-pointer flex"
          onClick={() => { setPrintDialogOpen(true); }}
        >
          <i className="material-icons">cloud_download</i>
        </div>

        <div
          className="p-3 hover:bg-gray-200 cursor-pointer flex"
          onClick={() => { setDownloadDialogOpen(); }}
        >
          <i className="material-icons">save</i>
        </div>

        <div className="text-gray-400 p-3">|</div>
        <a onClick={() => { ClearInfo(); }} className="p-3 danger hover:bg-gray-200 cursor-pointer flex"
        >
          <i className="material-icons">close</i>
        </a>
      </div>
    </div>
  );
};

export default PageController;
