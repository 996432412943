import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '../../../shared/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from "react-tooltip"
import { useDropzone } from 'react-dropzone'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Url } from "../../services/commonservice"



function MyDropzone() {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      var bodyFormData = new FormData();
      bodyFormData.append('file', file);
      axios.post(Url + "RawFileUpload", bodyFormData).then(res => {
        res = res.data;
        let value = JSON.parse(localStorage.getItem('state'));
        value.data.profile.photo = Url + 'Files/' + res.Filename;
        localStorage.setItem("state", JSON.stringify(value));
        toast.success("Image Uploaded Successfully", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
    });

  }, [])

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ onDrop })
  return (
    <div {...getRootProps()} className="grid-cols-10 myselector">
      <input {...getInputProps()} />
      <p> {!isDragActive && 'Click here or drop a file to upload!'}
        {isDragActive && !isDragReject && "Drop it like it's hot!"}
        {isDragReject && "File type not accepted, sorry!"}</p>
    </div>
  )
}
// eslint-disable-next-line no-shadow
const ProfileTab = ({ data, onChange }) => {
  const { t } = useTranslation('leftSidebar');
  return (
    <div>
      <div className="grid grid-cols-10">
        <TextField
          className="mb-6 col-span-8"
          label={t('profile.photoUrl.label')}
          placeholder="https://i.imgur.com/..."
          value={data.profile.photo}
          onChange={v => onChange('data.profile.photo', v)}
        />
        <ReactTooltip id="toolTip1" />
        <div className="col-span-2 infoicon">
          <FontAwesomeIcon icon={faInfoCircle} data-tip="Please use a square image with dimentions at least 300 x 300." data-for='toolTip1' data-place='left' />
        </div>
      </div>
      <p className="col-span-12 text-center"><strong>OR</strong></p>
      <div className="grid grid-cols-10">
        <div className="col-span-8">
          <MyDropzone accept="image/png, image/gif, image/jpeg" minSize={0}
            maxSize={256000} onDrop={(acceptedFiles) => { console.log(acceptedFiles); }}>
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p className="text-center"> {!isDragActive && 'Click here or drop a file to upload!'}
                    {isDragActive && !isDragReject && "Drop it like it's hot!"}
                    {isDragReject && "File type not accepted, sorry!"}</p>
                </div>
              </section>
            )}
          </MyDropzone>
        </div>
        <div className="col-span-2 infoicon">
          <FontAwesomeIcon icon={faInfoCircle} data-tip="JPEG or PNG file type only Maximum File Size - 250 KB" data-for='toolTip1' data-place='left' />
        </div>
      </div>

      <div className="grid grid-cols-2 col-gap-4">
        <TextField
          className="mb-6"
          label={t('profile.firstName.label')}
          placeholder="Chitti"
          value={data.profile.firstName}
          onChange={v => onChange('data.profile.firstName', v)}
        />

        <TextField
          className="mb-6"
          label={t('profile.lastName.label')}
          placeholder="2.0"
          value={data.profile.lastName}
          onChange={v => onChange('data.profile.lastName', v)}
        />
      </div>

      <TextField
        className="mb-6"
        label={t('profile.subtitle.label')}
        placeholder="The Robot"
        value={data.profile.subtitle}
        onChange={v => onChange('data.profile.subtitle', v)}
      />
      <hr className="my-6" />
      <TextField
        className="mb-6"
        label={t('profile.address.line1.label')}
        placeholder="Kollywood"
        value={data.profile.address.line1}
        onChange={v => onChange('data.profile.address.line1', v)}
      />

      <TextField
        className="mb-6"
        label={t('profile.address.line2.label')}
        placeholder="Kodambakkam"
        value={data.profile.address.line2}
        onChange={v => onChange('data.profile.address.line2', v)}
      />

      <TextField
        className="mb-6"
        label={t('profile.address.line3.label')}
        placeholder="Chennai - 600024, INDIA"
        value={data.profile.address.line3}
        onChange={v => onChange('data.profile.address.line3', v)}
      />

      <hr className="my-6" />

      <TextField
        className="mb-6"
        label={t('profile.phone.label')}
        placeholder="+91 99999 99999"
        value={data.profile.phone}
        onChange={v => onChange('data.profile.phone', v)}
      />

      <TextField
        className="mb-6"
        label={t('profile.website.label')}
        placeholder="http://wesupportrajini.com"
        value={data.profile.website}
        onChange={v => onChange('data.profile.website', v)}
      />

      <TextField
        className="mb-6"
        label={t('profile.email.label')}
        placeholder="nrirajini@gmail.com"
        value={data.profile.email}
        onChange={v => onChange('data.profile.email', v)}
      />
    </div >
  );
};


export default ProfileTab;
