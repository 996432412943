import React, { Component } from 'react'

class MyFooter extends Component {
    render(){
        return(
            <div id="myfooter">
            <footer className="copyright-div">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                            <div className="copyright-content">
                                <p>&copy; 2020 - Powered by WeSupportRajini - All Rights Reserved.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            <div className="social">
                                <a href="https://www.facebook.com/WeSupportRajini/"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                <a href="https://twitter.com/WeSupportRajini?s=09"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                <a href="https://www.youtube.com/channel/UCGFC2X12RQ3WIppzKijG_ZQ"><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
                                <a href="mailto:nrirajini@gmail.com"><i className="fa fa-envelope" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <a href="#" id="return-to-top">
                <i className="fa fa-arrow-up" aria-hidden="true"></i>
            </a>
            </div>
        )
    }
}

export default MyFooter