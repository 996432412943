import React, { useContext } from 'react'
import "../../assets/scss/pages/authentication.scss"
import axios from "axios"
import { Url } from "./../services/commonservice"
import { LiveUrl } from "./../services/commonservice"

import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Label
} from "reactstrap"

// import PageContext from '../context/PageContext';

import MyFooter from './MyFooter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faShare, faEdit } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavbarHome from './NavbarHome'
let styles = {
    display: "inline-block!important"
}
// eslint-disable-next-line react/prefer-stateless-function
class home extends React.Component {
    // eslint-disable-next-line react/state-in-constructor
    state = {
        // eslint-disable-next-line react/no-unused-state
        activeTab: 1,
        email: "",
        password: "",
        myresume: [],
    }

    componentDidMount() {
        this.GetMyResume();
    }

    GetMyResume = () => {
        axios.post(Url + "GetMyResume", { userid: localStorage.getItem("UserId") }).then(res => {
            res = res.data;
            this.setState({ myresume: res.Resume_Id });
        });
    }

    GotoNewResume = () => {
        let test = { "data": { "profile": { "heading": "Profile", "photo": "", "firstName": "", "lastName": "", "subtitle": "", "address": { "line1": "", "line2": "", "line3": "" }, "phone": "", "website": "", "email": "" }, "objective": { "enable": true, "heading": "Objective", "body": "" }, "work": { "enable": true, "heading": "Work Experience", "items": [] }, "education": { "enable": true, "heading": "Education", "items": [] }, "awards": { "enable": true, "heading": "Honors & Awards", "items": [] }, "certifications": { "enable": true, "heading": "Certifications", "items": [] }, "skills": { "enable": true, "heading": "Skills", "items": [] }, "hobbies": { "enable": true, "heading": "Hobbies", "items": [] }, "languages": { "enable": true, "heading": "Languages", "items": [] }, "references": { "enable": true, "heading": "References", "items": [] }, "extras": { "enable": true, "heading": "Personal Information", "items": [] } }, "theme": { "layout": "Onyx", "font": { "family": "" }, "colors": { "background": "#ffffff", "primary": "#212121", "accent": "#f44336" } }, "settings": { "language": "en" } };
        localStorage.setItem("state", JSON.stringify(test));
        window.location.href = "/";
    }

    ResumeDelete = (ev) => {
        axios.post(Url + "DeleteResume", { id: ev }).then(res => {
            res = res.data;
            if (res.Status === 1) {
                this.GetMyResume();
                toast.success(res.Message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else {
                toast.error(res.Message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    // let pageContext = useContext(PageContext);
    // var { setPrintDialogOpen } = pageContext;

    render() {
        return (
            <div id="myhome">
                <NavbarHome />
                <div className="login-component">
                    <h1 className="display-4 text-center text-white">Welcome, {JSON.parse(localStorage.getItem("UserProfile")).name}!</h1>
                    <Row className="mybackground justify-content-center">
                        <Col
                            xs="8"
                            className="d-flex m-0 resumedata"
                        >
                            <Card className="bg-authentication login-card rounded-0 w-100">
                                <Row className="m-0">
                                    <Col lg="12" md="12" className="p-0">
                                        <Card className="rounded-0 mb-0 px-2">
                                            <CardBody>
                                                {/* <h4>Login</h4> */}
                                                <div id="height60">
                                                    <div id="createnewtext">Create/Edit your resume.</div>
                                                    <Button id="createnew" variant="outline-primary" onClick={() => { this.GotoNewResume() }}><FontAwesomeIcon icon={faPlus} /> Create Resume</Button>{' '}
                                                </div>

                                                <div id="resumecards" className="row">
                                                    {
                                                        this.state.myresume.map(element => {
                                                            return (
                                                                <Card
                                                                    className="resumecard col-xl-4 col-lg-4 col-md-6 col-sm-12"
                                                                    bg="Success"
                                                                    key="2"
                                                                    text={"success" === 'light' ? 'dark' : 'white'}
                                                                // style={{ width: '100%' }}
                                                                // className="mb-2"
                                                                >
                                                                    <CardBody className="bg-white">
                                                                        <CardTitle className="text-center resumename">{JSON.parse(element.resume_info).data.profile.firstName} {JSON.parse(element.resume_info).data.profile.lastName}
                                                                        </CardTitle>
                                                                        <hr className="my-2" />
                                                                        <CardText className="resumesubtitle">
                                                                            {JSON.parse(element.resume_info).data.profile.subtitle}
                                                                        </CardText>
                                                                        <br/>
                                                                        <CardText className="resumedate">
                                                                            <span className="lastupdate">Last Updated - </span>{element.last_update}
                                                                        </CardText>
                    
                                                                        <FontAwesomeIcon title="Edit" className="myicons" onClick={() => { localStorage.setItem('state', element.resume_info); window.location.href = '/?id=' + element.id + ''; }} icon={faEdit} />

                                                                        <FontAwesomeIcon title="Delete" className="myicons" onClick={() => this.ResumeDelete(element.id)} icon={faTrash} />

                                                                        <FontAwesomeIcon title="Share" className="myicons" icon={faShare} onClick={() => window.open(LiveUrl + 'openResume?token=' + element.token)} >

                                                                        </FontAwesomeIcon>
                                                                    </CardBody>
                                                                </Card>)
                                                        }
                                                        )
                                                    }
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row >
                </div>
                <MyFooter />
                <ToastContainer />
            </div>
        )
    }
}
export default home