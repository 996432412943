import React from 'react'
import "../../assets/scss/pages/authentication.scss"
import axios from "axios"
import { Url } from "./../services/commonservice"
import FacebookLogin from 'react-facebook-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login';
import MainContent from './MainContent'
import MyNavbar from './MyNavbar'
import MyFooter from './MyFooter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
// import './../../style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
let styles = {
    display: "inline-block!important"
}
// eslint-disable-next-line react/prefer-stateless-function
class login extends React.Component {
    // eslint-disable-next-line react/state-in-constructor
    state = {
        // eslint-disable-next-line react/no-unused-state
        activeTab: 1,
        email: "",
        password: ""
    }

    responseFacebook = (ev) => {
        if (ev.id) {
            localStorage.setItem("UserId", ev.id)
            localStorage.setItem("UserProfile", JSON.stringify(ev));
            this.RegsiterUser('Facebook', ev);
            // window.location.href = '/'
        } else {
            toast.error("Login Fail Due to Some Technical issue", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    responseGoogle = (ev) => {
        if (ev.googleId) {
            localStorage.setItem("UserId", ev.googleId);
            localStorage.setItem("UserProfile", JSON.stringify(ev.profileObj))
            this.RegsiterUser('Gmail', ev.profileObj);
            // window.location.href = '/'
        } else {
            toast.error("Login Fail Due to Some Technical issue", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    RegsiterUser = (Type, info) => {
        let Data = {};
        if (Type == "Facebook") {
            Data = { name: info.name, userid: info.id, loginwith: 'Facebook', email: '', profile: info.picture.data.url };
       
        } else {
            Data = { name: info.name, userid: info.googleId, loginwith: 'Gmail', email: info.email, profile: info.imageUrl };
        }
        axios.post(Url + "UserRegistration", Data).then(res => {
            res = res.data;
            if (res.Status == 1) {
                toast.success(res.Message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                let test = { "data": { "profile": { "heading": "Profile", "photo": "", "firstName": "", "lastName": "", "subtitle": "", "address": { "line1": "", "line2": "", "line3": "" }, "phone": "", "website": "", "email": "" }, "objective": { "enable": true, "heading": "Objective", "body": "" }, "work": { "enable": true, "heading": "Work Experience", "items": [] }, "education": { "enable": true, "heading": "Education", "items": [] }, "awards": { "enable": true, "heading": "Honors & Awards", "items": [] }, "certifications": { "enable": true, "heading": "Certifications", "items": [] }, "skills": { "enable": true, "heading": "Skills", "items": [] }, "hobbies": { "enable": true, "heading": "Hobbies", "items": [] }, "languages": { "enable": true, "heading": "Languages", "items": [] }, "references": { "enable": true, "heading": "References", "items": [] }, "extras": { "enable": true, "heading": "Personal Information", "items": [] } }, "theme": { "layout": "Onyx", "font": { "family": "" }, "colors": { "background": "#ffffff", "primary": "#212121", "accent": "#f44336" } }, "settings": { "language": "en" } };
                localStorage.setItem("state", JSON.stringify(test));
                localStorage.setItem("UserProfile", JSON.stringify(Data));
                window.location.href = "/";
            } else {
                toast.error(res.Message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        })
    }


    render() {
        return (
            <div>
                <MyNavbar />
                <MainContent />
                <section id="loginarea" className="get-started-main bg-gray section-padding"  data-scroll-nav="0">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-sm-12">
                                <div className="section-head text-center">
                                    <h3>
                                        Let's get started!
                                    </h3>
                                </div>
                            </div>

                            <div className="col-lg-4 offset-lg-2 col-md-12">
                                <div className="get-started-img-div">
                                    <img style={{maxWidth: "420px", margin: "0 auto"}} src="img/get-started-img.jpg" alt="" />
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-12">
                                <div className="get-started-content ">
                                    <p style={{minWidth: "300px"}}>
                                        Don’t have an account? Sign Up!
                                    </p>

                                    <ul>
                                        <li>
                                                <FacebookLogin
                                                        appId="1601354283356510"
                                                        // autoLoad={true}
                                                        fields="name,email,picture"
                                                        scope="public_profile"
                                                        callback={this.responseFacebook}
                                                        cssClass="btnFacebook"
                                                        textButton="&nbsp;&nbsp;Login with Facebook"
                                                        icon={<FontAwesomeIcon className="fbicon" icon={faFacebook} />}
                                                    />
                                        </li>


                                        <li>
                                                <GoogleLogin
                                                        clientId="1099228567963-ktd8cjouj6f3vbbovgn0aam69uqeu0n3.apps.googleusercontent.com"
                                                        onSuccess={this.responseGoogle}
                                                        // onFailure={this.responseGoogle}
                                                        className="btnGoogle"
                                                        icon={false}
                                                        style={false}
                                                    >
                                                        <FontAwesomeIcon icon={faGoogle} />
                                                        <span>&nbsp;&nbsp;Sign In with Google</span>
                                                    </GoogleLogin>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <MyFooter />
                <ToastContainer />
            </div>
        )
    }
}
export default login