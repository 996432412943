import React, { useState } from 'react';
import $ from 'jquery'

const sendLink = () => {
  return localStorage.getItem('UserId') ? '/resume' : '/login#loginarea'
}

$(window).scroll(function() {
	try {
		if ($(this).scrollTop() >= 50) {        // If page is scrolled more than 50px
			var element = document.getElementById("customnav");
			element.classList.add("nav-scroll");  // Fade in the arrow
		} else {
			var element = document.getElementById("customnav");
			element.classList.remove("nav-scroll");   // Else fade out the arrow
		}
	}
	catch(err) {
		console.log(err)
	}
});

const MyNavbar = (props) => {
  return (
    <div id="mynavbar">
      <nav id={"customnav"} className="navbar navbar-expand-lg nav-scroll">
		    <div className="container">
		        <a className="logo" href="/"><img src="img/logo.png" alt="logo" /></a>
		        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
		            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		            <span className="icon-bar"><i className="fa fa-bars"></i></span>
		        </button>
		        <div className="collapse navbar-collapse" id="navbarSupportedContent">
		            <ul className="navbar-nav ml-auto">
		                <li className="nav-item">
		                    <a className="nav-link" href="https://wesupportrajini.com" target="_blank">
		                        About Us
		                    </a>
		                </li>
		                <li className="nav-item">
		                    <a className="nav-link" href="https://www.facebook.com/groups/223506015628190/" target="_blank">
		                        Join Us
		                    </a>
		                </li>
		                <li className="nav-item">
		                    <a className="nav-link" href="mailto:nrirajini@gmail.com">
		                        Contact Us
		                    </a>
		                </li>
		                <li className="nav-item">
		                    <a className="nav-link" data-scroll-nav="0" href={sendLink()}>{localStorage.getItem('UserId') ? 'My Resume' : 'Build My Resume'}</a>
		                </li>
		            </ul>
		        </div>
		    </div>
		  </nav>
    </div>
  );
}

export default MyNavbar;
