import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button
} from 'reactstrap';

const NavbarHome = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div id="mynavbar2">
      <Navbar expand="md">
        <div className="container">
          {/* <div className="mynavbar"> */}
          <NavbarBrand href="/"><img src="img/logo.png" alt="placeholder" width="200" /></NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {/* <NavItem>
                <NavLink href="/login#benefits">Benefits</NavLink>
              </NavItem> */}
              {/* <NavItem  onClick={() => { console.log("Ye Chala"); window.location.href = "/resume"; }}> */}
              <NavItem>
                <NavLink href="https://wesupportrajini.com" target="_blank">About Us</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://www.facebook.com/groups/223506015628190/" target="_blank">Join Us</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="mailto:nrirajini@gmail.com">Contact Us</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/resume">My Resume</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => localStorage.clear()} href="/login">{localStorage.getItem('UserId') ? 'Logout' : 'Login'}</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
        {/* </div> */}
      </Navbar>
    </div>
  );
}

export default NavbarHome;
