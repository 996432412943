import React, { useContext } from 'react';
import { Url } from "../components/services/commonservice"
import PageContext from '../context/PageContext';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const getParams = (url) => {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
}


const OpenPageController = () => {
    const pageContext = useContext(PageContext);
    const { panZoomRef, setPrintDialogOpen } = pageContext;

    const zoomIn = () => panZoomRef.current.zoomIn(2);
    const zoomOut = () => panZoomRef.current.zoomOut(2);
    const centerReset = () => {
        panZoomRef.current.autoCenter(1);
        panZoomRef.current.reset(1);
    };
    if (getParams(window.location.href)["token"]) {
        
        axios.post(Url + "GetOpenResume", { token: getParams(window.location.href)["token"] }).then(res => {
            res = res.data;
            if (res.Status == 1) {
                localStorage.setItem('state', res.Resume_Id.resume_info);
            }
        });
    }


    return (
        <div
            id="pageController"
            className="absolute z-20 opacity-75 hover:opacity-100 transition-all duration-150"
        >
            <div className="text-2xl px-8 border border-gray-200 rounded-full bg-white flex justify-center items-center leading-none select-none">
                <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={zoomIn}>
                    <i className="material-icons">zoom_in</i>
                </div>

                <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={zoomOut}>
                    <i className="material-icons">zoom_out</i>
                </div>

                <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={centerReset}>
                    <i className="material-icons">center_focus_strong</i>
                </div>

                <div className="text-gray-400 p-3">|</div>

                <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={() => window.print()}>
                    <i className="material-icons">print</i>
                </div>

                {/* <div
                    className="p-3 hover:bg-gray-200 cursor-pointer flex"
                    onClick={() => { setPrintDialogOpen(true); }}
                >
                    <i className="material-icons">cloud_download</i>
                </div> */}
            </div>
            <ToastContainer />
        </div>
    );
};

export default OpenPageController;
