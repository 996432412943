import React from 'react';

import { withRouter } from 'react-router';
import { history } from '../../history'
export default function requireAuth(Component) {
    class AuthenticatedComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                // eslint-disable-next-line react/no-unused-state
                auth: localStorage.getItem("UserId")
            }
        }
        componentDidMount() {
            this.checkAuth();
        }
        checkAuth() {
            const location = this.props.location;
            const redirect = location.pathname + location.search;
            if (!localStorage.getItem("UserId")) {
                // this.history.push(`/login`);
                window.location.href = "/login";
            }
        }
        render() {
            return localStorage.getItem("UserId")
                ? <Component {...this.props} />
                : null;
        }
    }
    return withRouter(AuthenticatedComponent)
}