import React from 'react'

// export const Url = "http://localhost/resume/public/";
export const Url = "https://api.wesupportrajini.com/";
// export const LiveUrl="http://localhost:3000/";
export const LiveUrl="https://chitti.wesupportrajini.com/";


class Common extends React.Component { }


export default Common